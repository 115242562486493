import { Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { MuseService } from 'src/app/dash/services/muse.service';

@Component({
  selector: 'app-get-stream',
  templateUrl: './get-stream.component.html',
  styleUrls: ['./get-stream.component.css']
})
export class GetStreamComponent implements OnInit, OnDestroy {
  response: any;
  urlStream;
  urlStreamSubscription: Subscription;

  isLoading = true;
  authStatusSub: Subscription;

  serverVariables;

  formStatusChanges: Subscription;
  formValueChanges: Subscription;

  validationErrors: [];

  convertVideoLinkToStreamUrlForm: FormGroup = new FormGroup({
    convertVideoLinkToStreamUrl: new FormGroup({
      videoLinkUrl: new FormControl(null, [Validators.required]),
    }),
    convertVideoLinkToStreamUrlMeta: new FormGroup({
      creator: new FormControl(null, [Validators.required]),
    })
  });

  userId: string;
  hasError = false;

  constructor(
    private authService: AuthService,
    private museService: MuseService
  ) { }

  ngOnInit(): void {
    // get's logged in user, subscribes to auth status and stop loading when connected
    this.authStatusSub = this.authService.getAuthStatusListener().subscribe(
      authStatus => {}
    );
    // automatically sets author to logged in user
    this.userId = this.authService.getUserId();

    // subscribe to form status changes
    this.formStatusChanges = this.convertVideoLinkToStreamUrlForm.statusChanges.subscribe(
      // (status) => console.log(status)
    );

    // subscribe to form value changes
    this.formValueChanges = this.convertVideoLinkToStreamUrlForm.valueChanges.subscribe(
      // (value) => {}
    );

    this.urlStreamSubscription = this.museService.getUrlStreamListener().subscribe(
      response => {
        this.response = response
        this.urlStream = this.response.data.updatedLink.link.urlStream;
      }
    );

    this.isLoading = false;
  }

  onSubmit(form) {
    console.log(`submitted`, form.value);
    this.isLoading = true;
    this.submitConvertVideoLinkToStreamUrlForm(form.value);
  }

  async submitConvertVideoLinkToStreamUrlForm(form) {
    console.log(`submit Edit Server Variables Update args`, form);
    const videoLinkInput = {
      webAddress: form.convertVideoLinkToStreamUrl.videoLinkUrl,
      // userId: form.convertVideoLinkToStreamUrlMeta.creator
      userId: 169
    };
    // connect to server control service to push new values
    this.museService.convertVideoLinkToStreamUrl(videoLinkInput)
    .then((response) => {
      setTimeout(() => {
        this.isLoading = false;
      }, 800);
      console.log(response)
    });
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    this.formStatusChanges.unsubscribe();
    this.formValueChanges.unsubscribe();
  }

}
